import React, { useContext } from "react";
import styled from "@emotion/styled";
import Container from "@src/components/Container";
import { theme } from "@src/components/nessie-web";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { mediaQueries } from "@src/styles/theme";
import { Box } from "@src/components/Boxes";

const HTMLContainer = styled(Container)`
  margin-top: 40px;
  margin-bottom: 40px;
  h1,
  h2 {
    font-size: 24px;
    ${mediaQueries[0]} {
      font-size: 42px;
    }
  }
`;

const PageTitle = styled("h1")`
  box-sizing: border-box;
  color: #fff;
  font-size: 31px;
  font-weight: 400;
  line-height: 1.1;
  margin: 0;
  text-shadow: rgba(4, 76, 102, 0.5) 2px 2px 2px;
  text-align: center;

  ${mediaQueries[0]} {
    font-size: 52.8px;
    font-weight: 800;
  }
`;

const Title = ({ children }) => <PageTitle>{children}</PageTitle>;

const PageAccessibility = () => {
  const trContext = useContext(TranslationContext);
  const t = (path: string) => trContext.translate(`directus.page_accessibility.${path}`);

  return (
    <>
      <Box backgroundColor={theme.colors.aqua50} padding={[30, 50]}>
        <Container>
          <Title>{t("title")}</Title>
        </Container>
      </Box>
      <HTMLContainer>{t("legal_text")}</HTMLContainer>
    </>
  );
};

export default PageAccessibility;
